const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "empty" },
    component: () => import("@/views/default/MainView"),
  },
];

export default routes;
