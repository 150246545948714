<template>
  <main class="overflow-hidden">
    <component id="body" :is="layout"></component>
  </main>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";

export default {
  components: {
    EmptyLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "empty"}-layout`;
    },
  },
};
</script>

<style lang="scss">
#body {
  @apply flex flex-col items-center;
  min-height: 100vh;
}
</style>
