import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import VueScrollTo from "vue-scrollto";

import "@/assets/styles/tailwind.css";
import config from "@/config";

createApp(App)
  .use(router)
  .use(VueScrollTo)
  .provide("config", config)
  .mount("#app");
